
/** There are several bugs in the old calc that change results of calculations. Enable this mode to reproduce that behaviour. Unit testing use this mode as test cases are taken from old calc results. */
export function isCompatMode() {
	return import.meta.env.MODE == 'test';
}

export enum MarketStr {
	None = 'none',
	Sverige = 'sv-SE',
	Norge = 'nb-NO',
	Danmark = 'da-DK',
	Tyskland = 'de-DE',
}

export enum Material {
	None, Betong, Lertegel
}

export const assortments: { [key in MarketStr]?: { [key in Material]?: string } } = {
  [MarketStr.Sverige]: { [Material.Betong]: '2TSE22', [Material.Lertegel]: '2TSE22' },
  [MarketStr.Norge]: { [Material.Betong]: '2TNO22', [Material.Lertegel]: '2TNL22' },
  [MarketStr.Tyskland]: { [Material.Betong]: '2TSE22', [Material.Lertegel]: '2TSE22' },
};

export enum RoofModel {
	Standard, Mansard
}

export enum RoofForm {
	None, Sadel, Valm, Halvvalm, Pult
}

export enum NockPannaVariant {
	None, Standard, Alt
}

export enum NockPannaExtra {
	None, Nockbygel, Overlapp
}

export enum NockConnector {
	No, Yes
}

export enum PultPanna {
	None, PultPanna
}

export enum RoofTypeIndex {
	None = 'none',
	Sadel = 'saddle',
	Valm = 'hip',
	Halvvalm = 'half_hip',
	Mansard = 'attic_saddle',
	MansardValm = 'attic_hip',
	MansardHalvvalm = 'attic_half',
	Pulttak = 'pent',
	// extension exclusive types
	Vinkel = 'angle',
	Kupa = 'cover',
	Front = 'front',
	Annex = 'annex',
	HornVinkel = 'corner_angle',
	// extension children
	KupaSadel = 'cover_saddle',
	KupaHalvValm = 'cover_half_hip',
	VinkelSadel = 'angle_saddle',
	VinkelValm = 'angle_hip',
	VinkelHalvValm = 'angle_half_hip',
	FrontPulttak = 'front_pent',
	FrontValm = 'front_hip',
	PultPulttak = 'pent_pent',
	PultPulttakValm = 'pent_pent_hip',
	AnnexSadel = 'annex_saddle',
	AnnexValm = 'annex_hip',
	AnnexHalvValm = 'annex_half_hip',
	HornVinkelSadel = 'corner_angle_saddle',
	HornVinkelValm = 'corner_angle_hip',
	HornVinkelHalvValm = 'corner_angle_half_hip',
}

export enum Underlagstak {
	None = 'none',
	LättMedStröläkt = 'light_with_counter_battens',
	LättUtanStröläkt = 'light_without_counter_battens',
	RåspontMedPapp = 'raw_sheet_with_cardboard'
}

export enum RoofType {
	None, Sadel, Valm, Halvvalm, Mansard, MansardValm, MansardHalvvalm, Pulttak,
	VinkelTak, Kupa, Front, PultDel, Tillbyggnad, HörnVinkel
}

export function GetRoofType(index: RoofTypeIndex): RoofType {
	switch (index) {
    case RoofTypeIndex.None:
      return RoofType.None;
    case RoofTypeIndex.Sadel:
      return RoofType.Sadel;
    case RoofTypeIndex.Valm:
      return RoofType.Valm;
    case RoofTypeIndex.Halvvalm:
      return RoofType.Halvvalm;
    case RoofTypeIndex.Mansard:
      return RoofType.Mansard;
    case RoofTypeIndex.MansardValm:
      return RoofType.MansardValm;
    case RoofTypeIndex.MansardHalvvalm:
      return RoofType.MansardHalvvalm;
    case RoofTypeIndex.Pulttak:
      return RoofType.Pulttak;
    case RoofTypeIndex.PultPulttak:
    case RoofTypeIndex.PultPulttakValm:
      return RoofType.PultDel;
    case RoofTypeIndex.VinkelSadel:
    case RoofTypeIndex.VinkelValm:
    case RoofTypeIndex.VinkelHalvValm:
      return RoofType.VinkelTak;
    case RoofTypeIndex.KupaSadel:
    case RoofTypeIndex.KupaHalvValm:
      return RoofType.Kupa;
    case RoofTypeIndex.FrontPulttak:
    case RoofTypeIndex.FrontValm:
      return RoofType.Front;
    case RoofTypeIndex.AnnexSadel:
    case RoofTypeIndex.AnnexValm:
    case RoofTypeIndex.AnnexHalvValm:
      return RoofType.Tillbyggnad;
    case RoofTypeIndex.HornVinkelSadel:
    case RoofTypeIndex.HornVinkelValm:
    case RoofTypeIndex.HornVinkelHalvValm:
      return RoofType.HörnVinkel;
    default:
      throw new Error(`Unknown roof type index: ${index}`);
  }
}

export function GetRoofForm(index: RoofTypeIndex): RoofForm {
	switch (index) {
		case RoofTypeIndex.VinkelSadel:
		case RoofTypeIndex.KupaSadel:
		case RoofTypeIndex.AnnexSadel:
		case RoofTypeIndex.HornVinkelSadel:
		case RoofTypeIndex.FrontPulttak: // yes, really
		case RoofTypeIndex.PultPulttak: // yes, really
			return RoofForm.Sadel;

		case RoofTypeIndex.VinkelValm:
		case RoofTypeIndex.FrontValm:
		case RoofTypeIndex.AnnexValm:
		case RoofTypeIndex.HornVinkelValm:
		case RoofTypeIndex.PultPulttakValm: // yes, really
			return RoofForm.Valm;

		case RoofTypeIndex.VinkelHalvValm:
		case RoofTypeIndex.KupaHalvValm:
		case RoofTypeIndex.AnnexHalvValm:
		case RoofTypeIndex.HornVinkelHalvValm:
			return RoofForm.Halvvalm;

		case RoofTypeIndex.None:
			return RoofForm.None;

		default:
			throw new Error(`Unknown roof type index: ${index}`);
	}
}

export const MIN_INPUT_LENGTH = 100;
export const MAX_INPUT_LENGTH = 99990;
export const MIN_INPUT_WIDTH = 100;
export const MAX_INPUT_WIDTH = 99990;
export const MIN_INPUT_WIDTH2 = 100;
export const MAX_INPUT_WIDTH2 = 99990;
export const MIN_INPUT_SLOPE = 100;
export const MAX_INPUT_SLOPE = 50000;
export const MIN_INPUT_ANGLE = {
	[MarketStr.None]: 14,
	[MarketStr.Sverige]: 14,
	[MarketStr.Norge]: 14,
	[MarketStr.Tyskland]: 10,
};
export const MAX_INPUT_ANGLE = {
	[MarketStr.None]: 80,
	[MarketStr.Sverige]: 80,
	[MarketStr.Norge]: 80,
	[MarketStr.Tyskland]: 60,
};
export const MIN_INPUT_LAKT = {
	[MarketStr.None]: 266,
	[MarketStr.Sverige]: 266,
	[MarketStr.Norge]: 266,
	[MarketStr.Tyskland]: 315,
};
export const MAX_INPUT_LAKT = {
	[MarketStr.None]: 375,
	[MarketStr.Sverige]: 375,
	[MarketStr.Norge]: 375,
	[MarketStr.Tyskland]: 345,
};


/** Min. läktavstånd för att kunna använda vanlig gavelpanna istället för bred */
export const GAVEL_MIN_LÄKTAVSTÅND = 335;

export const svinn = {
	takpannaExtra: 1.015,
	takpanna: 1.02,
	bärläkt: 1.10,
	ströläkt: 1.10,
	fästelement: 1.05,
	nockskruv: 1.05,
	nockpanna: 1.05,
	duk: 1.05,
	halvpanna: 1.02,
	nockklämma: 1.02,
	lertegel: 1.015
} as const;

export enum TileFamily {
	None = 0,
	Palema = 21,
	Exklusiv = 22,
	Carisma = 23,
	HansaFalsatLertegel = 45,
	HansaFalsatLertegelRakFramkant = 44,
	TvillingFalsatLertegel = 46,
	TvillingLertegelRakFramkant = 43,
	PianoFalsatLertegel = 47,
	Strängpressat2kupLertegel = 42,
	Mecklenburger = 48,
}

// NOTE: Any new tile finishes also need to be added to the TileFinishMap below
// Also, their translations need to be added and mapped in 'StepRoofTiles.vue'
export enum TileFinish {
	None, Benderit, Brilliant, Candor, Engoberad, Glaserad, Kristallengoberad, Natur, Obehandlad, Struktur, 'ECO Obehandlad', 'Kant detalj', Enfärgad, Grå, Mix, Reducerad, 'ECO OBH'
}

// NOTE: Any new tile colors also need to be added to the TileColorMap below
// Also, their translations need to be added and mapped in 'StepRoofTiles.vue'
export enum TileColor {
	None = 'None',
	AntikTegelrödStandardSvart = 'Antik tegelröd standard/svart',
	'Antik Gul/Brun' = 'Antik Gul/Brun',
	'Antik Grå/Svart' = 'Antik Grå/Svart',
	'Antik Grön/Koppar' = 'Antik Grön/Koppar',
	'Antik Röd/Svart' = 'Antik Röd/Svart',
	'Antik T-Röd Std/Svart' = 'Antik tegelröd Standard/Svart',
	'Antik Tegelröd/Svart' = 'Antik Tegelröd/Svart',
	'Tegelröd Std' = 'Tegelröd Standard',
	Basaltsvart = 'Basaltsvart',
	Blank = 'Blank',
	Bongossibrun = 'Bongossibrun',
	Bohusmix = 'Bohusmix',
	Brasibrun = 'Brasibrun',
	Brasilbrun = 'Brasilbrun',
	Brun = 'Brun',
	'Blå' = 'Blå',
	Diamantsvart = 'Diamantsvart',
	Djupsvart = 'Djupsvart',
	Djupgrå = 'Djupgrå',
	'ECO OBH' = 'ECO OBH',
	'Flash Blå/Svart' = 'Flash Blå/Svart',
	'Flash Brun/Svart' = 'Flash Brun/Svart',
	'Flash T-Röd/Brun' = 'Flash Tegelröd/Brun',
	'Flash Tegelröd/Brun' = 'Flash Tegelröd/Brun',
	'Flash Röd/Granit' = 'Flash Röd/Granit',
	'Flash Mellangrå/Granit' = 'Flash Mellangrå/Granit',
	'Flash Grön/Svart' = 'Flash Grön/Svart',
	Frisenblå = 'Frisenblå',
	Friesenblå = 'Friesenblå',
	Galvad = 'Galvad',
	Gammalröd = 'Gammalröd',
	Grafit = 'Grafit',
	Granit = 'Granit',
	Grå = 'Grå',
	'Grå Bohus' = 'Grå Bohus',
	Gråmix = 'Gråmix',
	'Grå/Svart' = 'Grå/Svart',
	Grön = 'Grön',
	Gul = 'Gul',
	Hollandsgrå = 'Hollandsgrå',
	Indiaröd = 'Indiaröd',
	Kastanjebrun = 'Kastanjebrun',
	Keniaröd = 'Keniaröd',
	Kieselgrå = 'Kieselgrå',
	Koppar = 'Koppar',
	Lavaröd = 'Lavaröd',
	'Lertegel Ytb Diverse Färger' = 'Lertegel Ytb Diverse Färger',
	Ljusgrå = 'Ljusgrå',
	Mahogniröd = 'Mahognyröd',
	Marinblå = 'Marinblå',
	Mellangrå = 'Mellangrå',
	Mörkbrun = 'Mörkbrun',
	Mörkgrå = 'Mörkgrå',
	Mörkgrön = 'Mörkgrön',
	Nattsvart = 'Nattsvart',
	Naturbunt = 'Naturbunt',
	Naturröd = 'Naturröd',
	Nötbrun = 'Nötbrun',
	Olivgrön = 'Olivgrön',
	Oxfordgrön = 'Oxfordgrön',
	Platinagrå = 'Platinagrå',
	Pärlvit = 'Pärlvit',
	Rapsgul = 'Rapsgul',
	Reducerad = 'Reducerad',
	Rostfri = 'Rostfri',
	Röd = 'Röd',
	'Röd Bohus' = 'Röd Bohus',
	Rustikal = 'Rustikal',
	Schilgrön = 'Schilgrön',
	Schilfgrön = 'Schilfgrön',
	Skiffer = 'Skiffer',
	Skiffersvart = 'Skiffersvart',
	Silver = 'Silver',
	Slottssortering = 'Slottsortering',
	Stören = 'Stören',
	Supervit = 'Supervit',
	Svart = 'Svart',
	Tegelröd = 'Tegelröd',
	Terrabrun = 'Terrabrun',
	Terracottaröd = 'Terracottaröd',
	Titansvart = 'Titansvart',
	'Ytbehandlad Ljusgul' = 'Ytbehandlad Ljusgul',
	Vinröd = 'Vinröd',
	Vånga = 'Vånga',
	Kastanj = 'Kastanj',
}

export const PrefixMap: { [key: number]: string } = {
	[TileFamily.Palema]: '0200',
	[TileFamily.Exklusiv]: '0100',
	[TileFamily.Carisma]: 'C000',
	[TileFamily.HansaFalsatLertegel]: 'H100',
	[TileFamily.HansaFalsatLertegelRakFramkant]: 'H101',
	[TileFamily.TvillingFalsatLertegel]: 'H200',
	[TileFamily.TvillingLertegelRakFramkant]: 'H201',
	[TileFamily.PianoFalsatLertegel]: 'H000',
	[TileFamily.Strängpressat2kupLertegel]: 'L201',
	[TileFamily.Mecklenburger]: 'M200',
};

export const TileFinishMap: { [key: string]: string } = {
  [TileFinish.Benderit]: 'Benderit',
  [TileFinish.Brilliant]: 'Brilliant',
  [TileFinish.Candor]: 'Candor',
  [TileFinish.Engoberad]: 'Engoberad',
  [TileFinish.Glaserad]: 'Glaserad',
  [TileFinish.Kristallengoberad]: 'Kristallengoberad',
  [TileFinish.Natur]: 'Natur',
  [TileFinish.Obehandlad]: 'Obehandlad',
  [TileFinish.Struktur]: 'Struktur',
  [TileFinish['ECO Obehandlad']]: 'ECO Obehandlad',
  [TileFinish['Kant detalj']]: 'Kant detalj',
  [TileFinish.Enfärgad]: 'Enfärgad',
  [TileFinish.Grå]: 'Grå',
  [TileFinish.Mix]: 'Mix',
  [TileFinish.Reducerad]: 'Reducerad',
  [TileFinish['ECO OBH']]: 'ECO Obehandlad',
};

export const TileFamilyMap: { [key: string]: string } = {
	[TileFamily.Palema]: 'Palema',
	[TileFamily.Exklusiv]: 'Exklusiv',
	[TileFamily.Carisma]: 'Carisma',
	[TileFamily.HansaFalsatLertegel]: 'Hansa',
	[TileFamily.HansaFalsatLertegelRakFramkant]: 'Hansa RAK',
	[TileFamily.TvillingFalsatLertegel]: 'Tvilling',
	[TileFamily.TvillingLertegelRakFramkant]: 'Tvilling RAK',
	[TileFamily.PianoFalsatLertegel]: 'Piano',
	[TileFamily.Strängpressat2kupLertegel]: 'Höganäs',
};

export enum GavelTillval {
	None, Gavelpanna, Gavelbeslag, Dubbelvingadpanna,
}

export enum RoofArticle {
	None,
	TakPanna,
	NockPanna,
	NockPannaAlt,
	NockBörjan,
	NockSlut,
	NockBörjanGavel, // BENT-107: Special nockbörjan tiles to use when gavelpannor are selected
	NockSlutGavel,
	NockBörjanDubbelfals,
	BörjanValm,
	BörjanValmAlt,
	NockBörjanAlt,
	NockSlutAlt,
	NockTBörjan,
	NockTSlut,
	NockTBörjanAlt,
	ValmKlocka,
	ValmKlockaAlt,
	ValmKlockaBörjan,
	ValmKlockaSlut,
	NockXLutande,
	NockXLutandeAlt,
	NockXPlan,
	HalvPanna,
	NockAnslutning,
	GavelPannaNAVänster,
	GavelPannaNAHöger,
	GavelPannaHalvVänster,
	GavelPannaHalvHöger,
	GavelPannaVänster,
	GavelPannaHöger,
	GavelPannaVänsterBred,
	GavelPannaHögerBred,
	GavelPannaHalvVänsterBred,
	GavelPannaHalvHögerBred,
	GavelPannaDubbelfals,
	GavelBeslag,
	DubbelvingadPanna,
	MansardPanna,
	PultPanna,
	PultPannaHalv,
	PultPannaGavelVänster,
	PultPannaGavelHöger,
	PultPannaGavelHalvVänster,
	PultPannaGavelHalvHöger,
	PultHalvPanna,
	Ventilationshuv,
	Ventilationshuv2,
	VentilationshuvMontering,
	Avluftare,
	Avluftare2,
	AvluftareLertegel,
	AvluftarePlåt,
	AvluftarePlåtMontering,
	AvluftareTyskSpitze,
	LösVenthattSpitze,
	Dunstrohrlufter100110,
	Entwässerunglü100110,
	Dunstrohrlufter125,
	Entwässerunglü125,
	Dunstrohrlufter160,
	FlexrörAvluftare10070,
	FlexrörAvluftare12570,
	VentileradLäkt,
	Kragrör,
	Rififi,
	InfästTaksteg,
	Taksteg,
	Glidskydd,
	Snöglidhinder,
	SnöglidhinderKonsol,
	Valmtätning300,
	Valmtätning340,
	Valmtätning370,
	Ventinock,
	LuftningsPanna,
	Rändalstätning,
	VenTakfotsläkt,
	GastDurchgGS,
	AntennenDurchgPanna,
	AntennenAufsatz,
	CBygel,
	Fågelband,
	Fågelband5mSvart,
	Fågelband5mRöd,
	VentiFågelband,
	VentiLäktPlast,
	Tätningsbeslag,
	RidgePlate,
	RidgePlateMount,
	ChimneySealant5m,
	ChimneySealant7m,
	ChimneySealant8m,
	RidgeSealant,
	VentivalmPlast,
	VentivalmBorst,
	Tätningskloss,
	Avrinningsplåt,
	Takluftare,
	Nocktätningsremsa,
	SpikPanna,
	SkruvPanna,
	Takpannekrok,
	Stormclips,
	// Stormclips2,
	SidofallsKlammer,
	DiagonalClip,
	DiagonalClipZial,
	// SDiagonalClips25, //Stormclips2
	EasyClip, EasyClip38, EasyClip50, EasyClip45x70, EasyClip30x48, EasyClip36x48, // Stormclips3,
	// SDiagonalClips45, //Stormclips3
	TakkrokJP,
	SKlammer,
	Nockskruv,
	NockskruvAlt,
	Nockklammer,
	NockKleeblatt,
	RänndalsOValmklipps,
	NockklammerAlt,
	Takfotsklammer,
	Nockbrädshållare,
	Sturmklammer456VA30x50Palema,
	Sturmklammer456Zial30x50Palema,
	Sturmklammer456VA40x60Palema,
	Sturmklammer456Zial40x60Palema,
	SturmklammerNordmark2Zial,
	Sturmklammer456VA30x50Mecklenburger,
	Sturmklammer456Zial30x50Mecklenburger,
	Sturmklammer456VA40x60Mecklenburger,
	Sturmklammer456Zial40x60Mecklenburger,
	SturmklammerExklusivZial,
	SturmklammerCarismaZial,
	SkruvTillBetongtillbehör,
	SkruvTillBetongtillbehörRostfri,
	ItemPallets,
	FreightZones,
	FreightAccessories,
	FreightOther,
	FreightLift,
	FreightNone,
	PackagePallets,
	PackagePalletsEU,
	TakPannaHalfPallet,
	UserProductsOther,
	DeletedProducts,

	// Roof security
	Bärläktsteg45,
	Bärläktsteg25,
	KoppladeTaksteg,
	InfästningKoppladeTaksteg,
	Taklucka,
	Säkerhetskrok,
	Snökrok,
	Fästögla,
	SpårPanna,
	SpårPannaHöger,
	SpårPannaVänster,
	SpårPannaBörjan,
	SpårPannaSlut,
	InfästningsPannaEuro,
	SnowSlideObstacle,
	SnowSlideObstacleKonsol,
	IsStoppare,

	// Extras
	BTSUnderlagstak,

	// Placeholder articles, is replaced with SpikPanna or other depending on market
	Fästelement,
	GavelPultPanna,
	NockAnslutningsGavelPanna,
	Nockskruv2,
	XNock,
	TNock,
	TNockAlt,
}

export enum NockTile {
	None,
	NockPiano,
	NockUniversal,
	NockCarismaBygel,
	NockCarismaÖverlapp,
	NockKleeblatt,
	Nock230250,
	Nock300Fals
}

export const ProductGroup = {
	'0': 'Roof tile',
	'10': 'Concrete accessories',
	'20': 'Ventilation and penetrations',
	'30': 'Roof safety products',
	'40': 'Fittings and ventilation',
	'50': 'Installation solutions',
	//	'60':	'Other accessories',
	'70': 'Freight by zones',
	'75': 'Freight other',
	'80': 'Other',
	//	'85':	'Packaging',
	'90': 'Customer unique',
	'99': 'Expired',
};
export type ProductGroup = keyof typeof ProductGroup;

const productGroupBuckets: { [key in ProductGroup]: RoofArticle[] } = {
	'0': [RoofArticle.TakPanna],
	'10': [RoofArticle.NockPanna, RoofArticle.NockPannaAlt, RoofArticle.NockBörjan, RoofArticle.NockSlut, RoofArticle.BörjanValm, RoofArticle.BörjanValmAlt,
	RoofArticle.NockBörjanAlt, RoofArticle.NockSlutAlt, RoofArticle.NockTBörjan, RoofArticle.NockTSlut, RoofArticle.ValmKlocka, RoofArticle.ValmKlockaBörjan,
	RoofArticle.ValmKlockaSlut, RoofArticle.NockXLutande, RoofArticle.NockXPlan, RoofArticle.HalvPanna, RoofArticle.NockAnslutning,
	RoofArticle.GavelPannaNAVänster, RoofArticle.GavelPannaNAHöger, RoofArticle.GavelPannaHalvVänster, RoofArticle.GavelPannaHalvHöger,
	RoofArticle.GavelPannaVänster, RoofArticle.GavelPannaHöger, RoofArticle.GavelPannaVänsterBred, RoofArticle.GavelPannaHögerBred,
	RoofArticle.GavelPannaHalvVänsterBred, RoofArticle.GavelPannaHalvHögerBred, RoofArticle.GavelBeslag, RoofArticle.MansardPanna,
	RoofArticle.PultPanna, RoofArticle.PultHalvPanna],
	'20': [RoofArticle.Ventilationshuv, RoofArticle.Ventilationshuv2, RoofArticle.Avluftare, RoofArticle.Avluftare2, RoofArticle.Kragrör, RoofArticle.Rififi],
	'30': [RoofArticle.InfästTaksteg, RoofArticle.Taksteg, RoofArticle.Glidskydd, RoofArticle.Snöglidhinder, RoofArticle.SnöglidhinderKonsol],
	'40': [RoofArticle.Valmtätning300, RoofArticle.Valmtätning370, RoofArticle.Ventinock, RoofArticle.Rändalstätning, RoofArticle.VenTakfotsläkt,
	RoofArticle.CBygel, RoofArticle.Fågelband, RoofArticle.Tätningskloss, RoofArticle.Avrinningsplåt, RoofArticle.Takluftare, RoofArticle.Nocktätningsremsa],
	'50': [RoofArticle.SpikPanna, RoofArticle.SkruvPanna, RoofArticle.Takpannekrok, RoofArticle.Stormclips, RoofArticle.Nockskruv, RoofArticle.NockskruvAlt,
	RoofArticle.Nockklammer, RoofArticle.RänndalsOValmklipps, RoofArticle.NockklammerAlt, RoofArticle.Takfotsklammer, RoofArticle.Nockbrädshållare],
	'70': [RoofArticle.FreightZones, RoofArticle.FreightAccessories],
	'75': [RoofArticle.FreightOther, RoofArticle.FreightLift, RoofArticle.FreightNone],
	'80': [RoofArticle.PackagePallets, RoofArticle.PackagePalletsEU],
	'90': [RoofArticle.UserProductsOther],
	'99': [RoofArticle.DeletedProducts],
};

export const ArticleProductGroups: { [id: string]: ProductGroup; } = {};
for (const group of Object.keys(productGroupBuckets) as ProductGroup[])
	for (const art of productGroupBuckets[group])
		ArticleProductGroups[art as RoofArticle] = group;

/** Map of dimensions for each article used for calculations. Does not necessarily correspond to actual width/length of articles. For example the nock tile 0300XX
 *  has a real length of 420🌿mm but as each tile overlaps the next the effective length is only 357mm. These measurements were taken from the old roof calculation
 *  database.
 **/
export const ByggBredd: { [key: string]: number } = {
	'0100': 250, '0200': 300, '0210': 150, '0215': 0, '0230': 300, '0300': 357, '0303': 0, '0305': 263, '0306': 0, '0307': 263,
	'0308': 0, '0309': 263, '0310': 270, '0315': 250, '0316': 260, '0318': 280, '0320': 300, '0330': 310, '0350': 0, '0352': 0,
	'0353': 0, '0360': 350, '0361': 350, '0363': 0, '0365': 0, '0366': 350, '0367': 350, '0370': 0, '0371': 0, '0374': 0,
	'0375': 0, '0402': 293, '0403': 263, '0412': 293, '0413': 263, '0416': 0, '0418': 300, '0419': 250, '0420': 293, '0421': 263,
	'0422': 150, '0500': 300, '0501': 300, '0505': 250, '0506': 300, '0507': 0, '0508': 0, '0509': 0, '0511': 250, '0512': 300,
	'0516': 0, '0518': 0, '0519': 300, '0520': 0, '0521': 250, '0522': 300, '0523': 250, '0524': 300, '0525': 0, '0527': 250,
	'0528': 0, '0529': 0, '0530': 0, '0532': 0, '0537': 0, '0538': 0, '0539': 0, '0540': 0, '0542': 0, '0545': 300,
	'0560': 300, '0563': 300, '0570': 300, '0574': 0, '0576': 0, '0598': 250, '0599': 300, '0600': 0, '0601': 0, '0602': 0,
	'0603': 0, '0604': 0, '0605': 0, '0606': 0, '0607': 0, '0608': 0, '0610': 0, '0612': 0, '0650': 0, '0651': 0,
	'0700': 0, '0701': 0, '0721': 0, '0722': 0, '0723': 0, '0724': 0, '0729': 0, '0732': 300, '0733': 300, '0742': 0,
	'0745': 0, '0752': 0, '0774': 2000, '0777': 2000, '0778': 0, '0780': 0, '0781': 0, '0782': 0, '0783': 0, '0784': 0,
	'0786': 2000, '0787': 0, '0800': 0, '0801': 0, '0802': 0, '0804': 0, '0805': 0, '0807': 0, '0808': 0, '0809': 0,
	'0810': 0, '0811': 0, '0812': 0, '0813': 0, '0814': 0, '0816': 0, '0817': 0, '0818': 0, '0825': 0, '0828': 0,
	'0829': 0, '0835': 0, '0836': 0, '0845': 0, '0848': 0, '0849': 0, '0850': 0, '0862': 0, '0869': 150, '0876': 300,
	'0877': 300, '0879': 250, '0882': 300, '0885': 300, '0886': 300, '0888': 300, '0893': 300, '0894': 300, '0897': 0, '0899': 0,
	'0901': 0, '0902': 900, '0907': 5000, '0911': 0, '0912': 0, '0914': 0, '0915': 1000, '0916': 5000, '0918': 5000, '0919': 1260,
	'0920': 1000, '0921': 5000, '0924': 0, '0930': 0, '0931': 0, '0932': 0, '0933': 0, '0936': 0, '0937': 0, '0939': 0,
	'0940': 0, '0942': 0, '0943': 0, '0944': 0, '0946': 0, '0947': 0, '0948': 0, '0955': 0, '0961': 0, '0962': 0,
	'0963': 0, '0964': 0, '0965': 0, '0972': 0, '0975': 0, '0976': 0, '0977': 0, '0983': 0, '0984': 0, '0985': 0,
	'0986': 0, '0987': 0, '0989': 0, '0999': 0, '1106': 250, '1132': 0, '1133': 0, '1134': 0, '1135': 0, '1136': 0,
	'1138': 0, '1139': 0, '1142': 0, '1144': 0, '1145': 0, '1146': 0, '1147': 0, '1148': 0, '1150': 5000, '1152': 1000,
	'1156': 0, '1170': 0, '1180': 1500, '1181': 0, '1184': 1110, '1185': 1000, '1201': 0, '1202': 0, '1204': 0, '1210': 0,
	'1211': 0, '1213': 0, '1214': 0, '1216': 0, '1217': 0, '1229': 0, '1237': 0, '1240': 0, '1241': 0, '1242': 50000,
	'1280': 25000, '1282': 50000, '1284': 0, '1285': 0, '1286': 0, '1287': 0, '1288': 0, '1289': 0, '1291': 0, '1294': 0,
	'1295': 25000, '1371': 0, '1372': 0, '1419': 0, '1426': 0, '1427': 0, '6903': 0, '7061': 0, '8312': 0, '9990': 0,
	'9991': 0, '9992': 0, 'C000': 250, 'C212': 125, 'C300': 420, 'C315': 350, 'C350': 0, 'C360': 350, 'C418': 250, 'C422': 250,
	'C500': 0, 'C501': 0, 'C509': 0, 'C512': 0, 'C522': 300, 'C524': 300, 'C560': 0, 'C570': 0, 'C733': 250, 'C782': 0,
	'C788': 2000, 'C789': 0, 'C848': 250, 'C876': 0, 'C877': 0, 'C879': 250, 'C885': 0, 'C887': 250, 'C922': 1000, 'C960': 0,
	'C971': 0, 'C972': 0, 'C974': 350, 'C981': 0, 'H000': 204, 'H100': 210, 'H101': 210, 'H103': 210, 'H200': 344, 'H201': 344, 'H203': 172, 'H210': 172,
	'H212': 102, 'H300': 333, 'H301': 333, 'H302': 375, 'H304': 333, 'H315': 380, 'H316': 380, 'H317': 380, 'H319': 380, 'H400': 230, 'H401': 195,
	'H402': 230, 'H403': 195, 'H410': 214, 'H411': 180, 'H412': 214, 'H413': 180, 'H502': 0, 'H721': 0, 'H723': 0, 'H733': 204,
	'H764': 0, 'H809': 0, 'L100': 200, 'L200': 250, 'L205': 0, 'L300': 277, 'L315': 275, 'L316': 275, 'L355': 275, 'L356': 275, 'L505': 190,
	'L506': 235, 'L511': 0, 'L512': 0, 'L521': 0, 'L522': 0, 'L526': 0, 'L541': 0, 'L542': 235, 'L721': 0, 'L722': 0,
	'L725': 0, 'L726': 0, 'L801': 0, 'L825': 0, 'L940': 0, 'L961': 0, 'L962': 0, 'M200': 300, 'M210': 150, 'M215': 330,
	'M230': 300, 'M400': 293, 'M401': 263, 'M410': 293, 'M411': 293, 'M418': 300, 'M420': 293, 'M421': 263, 'M422': 150, 'M500': 300,
	'M501': 300, 'M509': 300, 'M522': 300, 'M524': 300, 'M560': 300, 'M570': 300, 'M732': 300, 'M733': 300, 'M848': 0, 'M850': 300,
	'M869': 300, 'M876': 300, 'M877': 300, 'M882': 300, 'M885': 300, 'M893': 300, 'M894': 300, 'S065': 0, 'S073': 0, 'S882': 0,
	'S888': 0, 'T012': 0, 'T024': 0, 'U030': 0, 'V030': 0, 'X030': 0, 'Y030': 0
};

export enum LathDistance {
	None = "none",
	Calculated = "calculated",
	Fixed = "fixed"
}

export type ArticleSum = { [key in RoofArticle]?: number };

/** Iterate through each enumerable key in a and b, summing each value into a */
export function addArticleSum(a: ArticleSum, b: ArticleSum): void {
	for (const key of Object.keys(b)) {
		const artKey = parseInt(key) as RoofArticle;
		a[artKey] = (a?.[artKey] ?? 0) + (b?.[artKey] ?? 0);
	}
}

/** Bankers rounding implementation of round to be used in all calculations as this is how VB rounds */
export function round(n: number, d = 0) {
	const x = n * Math.pow(10, d);
	const r = Math.round(x);
	const br = ((Math.abs(x) % 1) === 0.5) ? (((0 === (r % 2))) ? r : r - 1) : r;
	return br / Math.pow(10, d);
}

/** Round function with customizable cut-off point */
export function roundCutoff(n: number, cutoff: number, le=true): number {
	if (le)
		return n - Math.floor(n) <= cutoff ? Math.floor(n) : Math.ceil(n);
	else
		return n - Math.floor(n) <  cutoff ? Math.floor(n) : Math.ceil(n);
}

