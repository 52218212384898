import type { ProjectData, ListFilters } from '@/project';
import type { Article, ArticleMap } from '@/types';

import pinia from '@/store/store';
import { useAccountStore } from '@/store/account';
import { useProjectStore } from '@/store/project';
import axios from 'axios';
import router from '@/router';

const cancel = {} as Record<string, AbortController>;

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    // append 'Authorization' header with token
    const { getSession } = useAccountStore(pinia);
    const { getMarket } = useProjectStore(pinia);
    const token = getSession;
    if (token) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (!config.params) config.params = {};
    if (!config.params.region) {
      try {
        const region = getMarket.split('-')[1];
        config.params.region = region;
      } catch (e) {
        console.error("Couldn't get region, using default", e);
      }
    }
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  },
);

export function getAvailableLists(page = 1, perPage = 10, filters: ListFilters) {
  cancel[`availableLists`] = new AbortController();
  return instance
    .post('availableLists', filters, {
      params: {
        page,
        perPage,
      },
      signal: cancel[`availableLists`].signal,
    })
    .then((res) => res.data?.data || {});
}

export function getList(id?: string) {
  id = id || '';
  return instance
    .get(`/lists/${id}`)
    .then((res) => res.data?.data)
    .catch((r) => {
      // if 404, redirect
      if (r?.response?.status === 404) {
        router.replace({ name: '404' });
      }
      return {};
    });
}

export function getDuplicateList(id?: string) {
  id = id || '';
  return instance
    .get(`/lists/${id}?duplicate=1`)
    .then((res) => res.data?.data)
    .catch((r) => {
      if (r?.response?.status === 404) {
        throw new Error('List could not be found');
      }
      return {};
    });
}

export function updateList(data: ProjectData, id: string) {
  if (!id) {
    throw new Error('id is required');
  }
  return instance.post(`/lists/${id}`, data).then((res) => {
    // console.debug('updateList', {
    //   ...res.data?.data,
    // });
    return {
      ...res.data,
      ...res.data?.data,
    };
  });
}

export function getListIdByShorturl(shorturl: string) {
  return instance.get(`/shorturl/${shorturl}`).then((res) => res.data?.url);
}

/* Article functions */
/* Don't use these functions directly, use the ArticleStore instead */
export function getArticlesByPrefix(prefix: string, params?: Record<string, unknown>, signal?: AbortSignal): Promise<ArticleMap> {
  return instance.get(`/articles/${prefix}`, { params: { size: 100, ...params }, signal: signal }).then((res) => {
    const resArticles = res.data as Article[];
    if (Array.isArray(resArticles) === false) {
      console.error('Unexpected response when fetching articles', resArticles);
      throw new Error('Unexpected response when fetching articles');
    }
    return resArticles.reduce((acc, cur) => ({ [cur.ArtNr]: cur, ...acc }), {});
  });
}

export function deleteList(id: string) {
  return instance.delete(`/lists/${id}`);
}

export function getArticles(artnrs: string[]): Promise<ArticleMap> {
  return instance
    .post(`/articles`, {
      filters: {
        ArtNr: artnrs,
      },
    })
    .then((res) =>
      res.data.reduce((acc: ArticleMap, a: Article) => {
        acc[a.ArtNr] = a;
        return acc;
      }, {} as ArticleMap),
    );
}

export function getArticle(artnr: string, signal?: AbortSignal): Promise<Article> {
  return instance
    .post(`/articles`, {
      filters: {
        ArtNr: [artnr],
      },
      size: 1,
      signal
    })
    .then((res) => res.data[0] as Article);
}

export function getAccessories(
  assortment: string,
  colornumber?: string[],
  colorgroup?: string[],
  tilefamily?: string,
  exclude_tilefamily?: string[],
  productfamilies?: string[],
): Promise<ArticleMap> {
  return instance
    .post(`/articles`, {
      assortment,
      colornumber,
      colorgroup,
      tilefamily,
      exclude_tilefamily,
      productfamilies,
      size: 100,
    })
    .then((res) =>
      res.data.reduce((acc: ArticleMap, a: Article) => {
        acc[a.ArtNr] = a;
        return acc;
      }, {} as ArticleMap),
    );
}

export function cancelRequest(type = '', reason = 'canceled') {
  const controller = cancel[type];
  if (Array.isArray(controller)) {
    for (let i = 0; i < controller.length; i++) controller[i].abort(reason);
  } else if (controller) {
    controller.abort(reason);
  }
}

/* End Article functions */
