// import type { Roof } from "@/project";
import type { RadioOption } from '@/types';
import { RoofTypeIndex, TileFamily, MarketStr } from '@/calculation/common';
import { RoofTile } from '@/calculation/selection';
import { useProjectStore } from '@/store/project';

// export function setActiveSubstep(substep: string, roof: Roof) {
//     // if key not in activeRoof.value.step
//     if (!Object.keys(roof.step).includes(substep)) {
//         roof.step[substep] = StepState.Active;
//     }

//     for (const key in roof.step) {
//         // TODO: Actual validation here to determine if they should be set to Valid/Active/Inactive or Done/Inactive
//         if (key === substep) {
//             roof.step[key] = StepState.Valid;
//         } else {
//             roof.step[key] = StepState.Done;
//         }
//     }
// }

const alwaysActive = ['type', 'facadeHeight', 'exposedWeather', 'lathDistance', 'lathMeasurement', 'baseRoof'];
export const RoofTypeMeasurementFields = {
  [RoofTypeIndex.None]: [...alwaysActive] as string[],
  [RoofTypeIndex.Sadel]: [...alwaysActive, 'a', 'b', 'angle', 'slope'],
  [RoofTypeIndex.Valm]: [...alwaysActive, 'a', 'b', 'angle', 'slope'],
  [RoofTypeIndex.Halvvalm]: [...alwaysActive, 'a', 'b', 'c', 'angle', 'slope'],
  [RoofTypeIndex.Mansard]: [...alwaysActive, 'a', 'b', 'c', 'angle', 'slope', 'angle2', 'slope2'],
  [RoofTypeIndex.MansardValm]: [...alwaysActive, 'a', 'b', 'c', 'angle', 'slope', 'angle2', 'slope2'],
  [RoofTypeIndex.MansardHalvvalm]: [...alwaysActive, 'a', 'b', 'c', 'angle', 'slope', 'angle2', 'slope2'],
  [RoofTypeIndex.Pulttak]: [...alwaysActive, 'a', 'b', 'angle', 'slope'],
  [RoofTypeIndex.Vinkel]: [...alwaysActive] as string[], // Unused
  [RoofTypeIndex.Kupa]: [...alwaysActive] as string[], // Unused
  [RoofTypeIndex.Front]: [...alwaysActive] as string[], // Unused
  [RoofTypeIndex.Annex]: [...alwaysActive] as string[], // Unused
  [RoofTypeIndex.HornVinkel]: [...alwaysActive] as string[], // Unused
  [RoofTypeIndex.KupaSadel]: [...alwaysActive, 'a', 'b', 'angle', 'slope'],
  [RoofTypeIndex.KupaHalvValm]: [...alwaysActive, 'a', 'b', 'c', 'angle', 'slope'],
  [RoofTypeIndex.VinkelSadel]: [...alwaysActive, 'a', 'b', 'angle', 'slope'],
  [RoofTypeIndex.VinkelValm]: [...alwaysActive, 'a', 'b', 'angle', 'slope'],
  [RoofTypeIndex.VinkelHalvValm]: [...alwaysActive, 'a', 'b', 'c', 'angle', 'slope'],
  [RoofTypeIndex.FrontPulttak]: [...alwaysActive, 'a', 'b', 'angle', 'slope'],
  [RoofTypeIndex.FrontValm]: [...alwaysActive, 'a', 'b', 'angle', 'slope'],
  [RoofTypeIndex.PultPulttak]: [...alwaysActive, 'a', 'b', 'angle', 'slope'],
  [RoofTypeIndex.PultPulttakValm]: [...alwaysActive, 'a', 'b', 'angle', 'slope'],
  [RoofTypeIndex.AnnexSadel]: [...alwaysActive, 'a', 'b', 'angle', 'slope'],
  [RoofTypeIndex.AnnexValm]: [...alwaysActive, 'a', 'b', 'angle', 'slope'],
  [RoofTypeIndex.AnnexHalvValm]: [...alwaysActive, 'a', 'b', 'c', 'angle', 'slope'],
  [RoofTypeIndex.HornVinkelSadel]: [...alwaysActive, 'a', 'b', 'angle', 'slope'],
  [RoofTypeIndex.HornVinkelValm]: [...alwaysActive, 'a', 'b', 'angle', 'slope'],
  [RoofTypeIndex.HornVinkelHalvValm]: [...alwaysActive, 'a', 'b', 'c', 'angle', 'slope'],
};

export function getRoofTile(family: TileFamily) {
  switch (family) {
    case TileFamily.Palema:
      return RoofTile.Palema;
    case TileFamily.Exklusiv:
      return RoofTile.Exklusiv;
    case TileFamily.Carisma:
      return RoofTile.Carisma;
    case TileFamily.HansaFalsatLertegel:
      return RoofTile.Hansa;
    case TileFamily.HansaFalsatLertegelRakFramkant:
      return RoofTile.HansaRak;
    case TileFamily.TvillingFalsatLertegel:
      return RoofTile.Tvilling;
    case TileFamily.TvillingLertegelRakFramkant:
      return RoofTile.TvillingRak;
    case TileFamily.PianoFalsatLertegel:
      return RoofTile.Piano;
    case TileFamily.Strängpressat2kupLertegel:
      return RoofTile.Höganäs;
    case TileFamily.Mecklenburger:
      return RoofTile.Mecklenburger;
    default:
      return RoofTile.None;
  }
}

// Note: This should not be used unless necessary, use 'marketSpecificItemNos' on the 'RoofTile' class where possible
export function replaceByArtnr(artnr: string): string {
  const { market } = useProjectStore();

  const prefix = artnr.slice(0, -2) + 'xx';
  const suffix = artnr.slice(-2);

  const replacementArticles_NO: { [key: string]: string } = {
    '061020': '061220',
    '061024': '061224',
    '061037': '061220',
    '0522xx': '0885xx',
    C522xx: 'C885xx',
    '0521xx': '0886xx',
    H521xx: 'H886xx',
    H522xx: 'H885xx',
    H524xx: 'H884xx',
    '091620': '092420',
    '091624': '092424',
    '091637': '092437',
  };
  switch (market) {
    case MarketStr.Norge:
      if (replacementArticles_NO[prefix]) artnr = replacementArticles_NO[prefix].slice(0, -2) + suffix;
      else if (replacementArticles_NO[artnr]) artnr = replacementArticles_NO[artnr];
      break;
  }

  const replacementArticles: { [key: string]: string } = {
    // BENT-133: These accessories are replaced on initial fetch now, correct artno's are already in accessoryVariants
    // '072220': '076220',
    // '072420': '076220',
    // '072120': '076120',
  };
  if (replacementArticles[artnr]) artnr = replacementArticles[artnr];

  return artnr;
}

// Note: This should not be used unless necessary, use 'marketSpecificItemNos' on the 'RoofTile' class where possible
export function doArticleReplacements(options: RadioOption[]): RadioOption[] {
  return options.map((option) => {
    if (option.artnr) {
      const replaceLabel = option.artnr === option.label;
      option.artnr = replaceByArtnr(option.artnr);
      if (replaceLabel) option.label = option.artnr;
    }
    return option;
  });
}
